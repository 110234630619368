<template>
    <div 
        ref="workPlanWrapper" 
        class="mr-4">
        <a-popover 
            v-model="visible"
            destroyTooltipOnHide
            trigger="click"
            autoAdjustOverflow
            overlayClassName="work_plan_popover"
            @visibleChange="visibleChange">
            <a-button 
                type="primary"
                :loading="submitLoading"
                :ghost="visible ? false : true"
                class="flex items-center"
                :class="!visible && 'work_plan_btn'">
                Мой рабочий день
                <i class="fi fi-rr-play ml-2" />
            </a-button>
            <template #content>
                <a-spin 
                    class="w-full" 
                    size="small" 
                    :spinning="loading">
                    <div 
                        ref="workPlanPopWrap" 
                        class="wp_wrapper">
                        <div class="wp_wrapper__header flex items-center justify-between">
                            <div class="flex items-center">
                                <a-date-picker 
                                    :value="selectedDay"
                                    format="DD.MM.YYYY"
                                    :allowClear="false"
                                    :locale="locale"
                                    :disabledDate="disabledFutureDates"
                                    :getCalendarContainer="getPopupContainer"
                                    placeholder="Выберите день"
                                    @change="changeDate">
                                    <i slot="suffixIcon" class="fi fi-rr-calendar-lines" />
                                </a-date-picker>
                                <h4 class="ml-3">Мой рабочий день</h4>
                            </div>
                            <a-button 
                                type="ui" 
                                ghost
                                shape="circle"
                                size="small"
                                flaticon
                                icon="fi-rr-cross"
                                @click="visible = false" />
                        </div>
                        <div class="wp_wrapper__body">
                            <div class="body_block body_block__tasks">
                                <div 
                                    class="body_block__head"
                                    :style="isTaskScrolled && 'box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.02), 0px 3px 4px rgba(0, 0, 0, 0.08);'">
                                    <div class="block_title">Задачи</div>
                                </div>
                                <div 
                                    ref="taskList" 
                                    class="body_block__body"
                                    @scroll="taskListScroll">
                                    <a-form-model
                                        ref="ruleForm"
                                        :model="form">
                                        <div 
                                            v-for="(item, index) in form.plane_items" 
                                            :key="item.id"
                                            class="form_item"
                                            :class="form.plane_items.length > 1 && 'long'">
                                            <div class="form_item__row">
                                                <div class="flex items-center form_item_col form_item_col__1">
                                                    <div class="mr-2">
                                                        {{ index+1 }}
                                                    </div>
                                                    <a-form-model-item 
                                                        :prop="'plane_items.' + index + '.work_type'" 
                                                        :ref="'plane_items.' + index + '.work_type'" 
                                                        :rules="{
                                                            required: true,
                                                            message: $t('field_required'),
                                                            trigger: ['change', 'blur']
                                                        }"
                                                        class="mb-0 w-full">
                                                        <a-select 
                                                            v-model="item.work_type" 
                                                            size="large"
                                                            class="select_item w-full"
                                                            :disabled="isFieldDisabled"
                                                            :class="item.work_type && 'selected'"
                                                            :loading="taskWorkTypesLoading"
                                                            :getPopupContainer="getPopupContainer" 
                                                            placeholder="Тип работ"
                                                            @change="taskChange(index, item)">
                                                            <i class="fi fi-rr-angle-small-down" slot="suffixIcon" />
                                                            <a-select-option 
                                                                v-for="type in taskWorkTypes" 
                                                                :key="type.code" 
                                                                :value="type.code">
                                                                {{ type.string_view }}
                                                            </a-select-option>
                                                        </a-select>
                                                    </a-form-model-item>
                                                </div>
                                                <a-form-model-item 
                                                    :prop="'plane_items.' + index + '.task'" 
                                                    :ref="'plane_items.' + index + '.task'" 
                                                    :rules="{
                                                        required: true,
                                                        message: $t('field_required'),
                                                        trigger: ['change', 'blur'],
                                                    }"
                                                    class="mb-0 form_item_col form_item_col__2">
                                                    <DSelect
                                                        v-model="item.task" 
                                                        size="large"
                                                        apiUrl="/app_info/filtered_select_list/"
                                                        class="w-full"
                                                        showPlaceholder
                                                        :disabled="isFieldDisabled"
                                                        resultsKey="filteredSelectList"
                                                        :params="{
                                                            model: 'tasks.TaskModel',
                                                            filters: {'task_type_id':'task'},
                                                            first: item.task || null
                                                        }"
                                                        :getPContainer="getPopupContainer"
                                                        usePopupContainer
                                                        infinity
                                                        initList
                                                        showSearch
                                                        useSearchApi
                                                        placeholder="Выбрать задачу"
                                                        :default-active-first-option="false"
                                                        :filter-option="false"
                                                        :not-found-content="null"
                                                        @change="taskChange(index, item)" />
                                                </a-form-model-item>
                                                <a-form-model-item 
                                                    :prop="'plane_items.' + index + '.duration_plane'" 
                                                    :ref="'plane_items.' + index + '.duration_plane'" 
                                                    :rules="{
                                                        required: true,
                                                        message: $t('field_required'),
                                                        trigger: ['change', 'blur']
                                                    }"
                                                    class="mb-0 form_item_col form_item_col__3">
                                                    <a-input-number 
                                                        v-model="item.duration_plane" 
                                                        size="large" 
                                                        :min="0.1"
                                                        :step="0.1"
                                                        :disabled="isFieldDisabled"
                                                        :max="100"
                                                        class="w-full" 
                                                        placeholder="План, ч."
                                                        @change="taskChange(index, item)" />
                                                </a-form-model-item>
                                                <a-form-model-item 
                                                    :prop="'plane_items.' + index + '.duration_fact'" 
                                                    :ref="'plane_items.' + index + '.duration_fact'" 
                                                    :rules="(daily && !item.add && !editedDaily) ? {
                                                        required: true,
                                                        message: $t('field_required'),
                                                        trigger: ['change', 'blur']
                                                    } : null"
                                                    class="mb-0 form_item_col form_item_col__4">
                                                    <a-input-number 
                                                        v-model="item.duration_fact" 
                                                        size="large" 
                                                        :disabled="durationFactDisabled"
                                                        :min="0.1"
                                                        :step="0.1"
                                                        :max="100"
                                                        class="w-full" 
                                                        placeholder="Факт, ч."
                                                        @change="taskChange(index, item)" />
                                                </a-form-model-item>
                                                <div class="flex items-center justify-end form_item_col form_item_col__5">
                                                    <a-form-model-item 
                                                        v-if="checkTaskComment(item) && !emptyDay"
                                                        :prop="'plane_items.' + index + '.description'" 
                                                        :ref="'plane_items.' + index + '.description'" 
                                                        :class="item.description.length && 'validated'"
                                                        class="mb-0 comment_desc">
                                                        <a-popover 
                                                            v-model="item.commentVisible"
                                                            destroyTooltipOnHide
                                                            trigger="click"
                                                            :title="isCompleted ? 'Комментарий' : ''"
                                                            autoAdjustOverflow
                                                            :getPopupContainer="getPopupContainer"
                                                            overlayClassName="plan_comment_popover">
                                                            <a-badge 
                                                                :number-style="{
                                                                    backgroundColor: '#4ac428'
                                                                }"
                                                                :dot="item.description.length ? true : false">
                                                                <a-button 
                                                                    flaticon
                                                                    type="ui"
                                                                    ghost
                                                                    size="small"
                                                                    shape="circle"
                                                                    icon="fi-rr-comment-alt" />
                                                            </a-badge>
                                                            <template #content>
                                                                <div class="comment_wrapper">
                                                                    <div v-if="isCompleted" class="comment_desc_text">
                                                                        {{ item.description }}
                                                                    </div>
                                                                    <template v-else>
                                                                        <a-textarea
                                                                            v-model="item.description"
                                                                            placeholder="Комментарий к задаче"
                                                                            :auto-size="{ minRows: 3, maxRows: 7 }"
                                                                            @change="handleCommentChange($event, index, item)" />
                                                                        <div class="flex items-center justify-between pt-2">
                                                                            <!--<a-button 
                                                                                type="primary" 
                                                                                size="large"
                                                                                class="mr-2"
                                                                                @click="addComment(index, item)">
                                                                                Сохранить
                                                                            </a-button>-->
                                                                            <a-button 
                                                                                type="primary" 
                                                                                ghost 
                                                                                @click="closeComment(index, item)">
                                                                                Закрыть
                                                                            </a-button>
                                                                            <div class="comment_length">
                                                                                {{item.description.length}}/{{ commentMaxCount }}
                                                                            </div>
                                                                        </div>
                                                                    </template>
                                                                </div>
                                                            </template>
                                                        </a-popover>
                                                    </a-form-model-item>
                                                    <div 
                                                        v-if="!isCompleted && form.plane_items.length > 1" 
                                                        class="item_delete ml-2">
                                                        <a-button 
                                                            type="danger" 
                                                            flaticon
                                                            ghost
                                                            :loading="item.dLoading"
                                                            size="small"
                                                            shape="circle"
                                                            icon="fi-rr-trash"
                                                            @click="deleteItem(index, item)" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a-form-model>
                                </div>
                                <div v-if="!isCompleted && !emptyDay" class="body_block__footer">
                                    <a-button 
                                        type="link" 
                                        class="flex items-center px-0"
                                        @click="addTask()">
                                        <div class="flex items-center">
                                            <i class="fi fi-rr-plus-small mr-1" />
                                            Добавить задачу
                                        </div>
                                    </a-button>
                                </div>
                            </div>
                            <div class="body_block body_block__events">
                                <div 
                                    class="body_block__head"
                                    :style="isEventsScrolled && 'box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.02), 0px 3px 4px rgba(0, 0, 0, 0.08);'">
                                    <div class="block_title">События</div>
                                </div>
                                <div 
                                    ref="eventsList" 
                                    class="body_block__body"
                                    @scroll="eventsListScroll">
                                    <a-spin 
                                        :spinning="eventsLoading" 
                                        class="w-full">
                                        <div v-if="!events.length" class="empty_events">
                                            Нет событий
                                        </div>
                                        <div 
                                            v-for="event in events" 
                                            :key="event.id"  
                                            class="flex">
                                            <div 
                                                class="event_item flex items-center"
                                                @click="openEvent(event.id)">
                                                <i class="fi fi-rr-clock mr-2" />
                                                <span :class="event.is_finished && 'line-through'">
                                                    <template v-if="event.all_day">{{ $t('calendar.all_day') }}</template>
                                                    <template v-else>
                                                        {{ $moment(event.start_at).format('HH:mm') }} 
                                                        <template v-if="event.end_at">- {{ $moment(event.end_at).format('HH:mm') }}</template>
                                                    </template>
                                                    {{ event.name }}
                                                </span>
                                            </div>
                                        </div>
                                    </a-spin>
                                </div>
                            </div>
                        </div>
                        <div class="wp_wrapper__footer flex items-center justify-between">
                            <template v-if="!emptyDay">
                                <div class="flex items-center">
                                    <a-button 
                                        v-if="!isCompleted"
                                        type="primary" 
                                        :disabled="loading"
                                        class="px-6 mr-4"
                                        :loading="submitLoading"
                                        @click="dailyHandle()">
                                        <template v-if="editedDaily">
                                            Сохранить изменения
                                        </template>
                                        <template v-else>
                                            {{ daily ? 'Завершить день' : 'Начать день' }}
                                        </template>
                                    </a-button>
                                    <div 
                                        v-if="daily && totalDurationFact > 0" 
                                        class="work_hours">
                                        <span>Отработано:</span> {{ totalDurationFact }} ч
                                    </div>
                                </div>
                                <a-popover 
                                    v-if="checkDailyComment"
                                    v-model="commentVisible"
                                    destroyTooltipOnHide
                                    trigger="click"
                                    autoAdjustOverflow
                                    :getPopupContainer="getPopupContainer"
                                    overlayClassName="plan_comment_popover">
                                    <a-button 
                                        type="link"
                                        flaticon
                                        class="flex items-center"
                                        :icon="isCompleted ? '' : 'fi-rr-plus-small'">
                                        {{ isCompleted ? 'Комментарий' : 'Добавить комментарий' }}
                                    </a-button>
                                    <template #content>
                                        <div class="comment_wrapper">
                                            <div v-if="isCompleted" class="comment_desc_text">
                                                {{ form.description }}
                                            </div>
                                            <template v-else>
                                                <a-textarea
                                                    v-model="form.description"
                                                    placeholder="Комментарий"
                                                    @change="handleDeilyCommentChange"
                                                    :auto-size="{ minRows: 3, maxRows: 7 }"/>
                                                <div class="flex items-center pt-2 justify-between">
                                                    <div class="flex items-center">
                                                        <a-button 
                                                            type="primary" 
                                                            @click="addDailyComment()">
                                                            Сохранить
                                                        </a-button>
                                                        <a-button 
                                                            type="primary" 
                                                            ghost 
                                                            class="ml-2"
                                                            @click="commentVisible = false">
                                                            Закрыть
                                                        </a-button>
                                                    </div>
                                                    <div class="comment_length">
                                                        {{ form.description.length }}/{{ commentMaxCount }}
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                </a-popover>
                            </template>
                        </div>
                    </div>
                </a-spin>
            </template>
        </a-popover>
    </div>
</template>

<script>
import locale from 'ant-design-vue/es/date-picker/locale/ru_RU'
import DSelect from '@apps/DrawerSelect/Select.vue'
const dateFormat = "YYYY-MM-DD"
import { dailyGenerate } from './utils.js'
import eventBus from '@/utils/eventBus.js'
//let editTimer;
//let addTimer;
export default {
    components: {
        DSelect
    },
    data() {
        return {
            commentMaxCount: 500,
            locale,
            emptyDay: false,
            isTaskScrolled: false,
            isEventsScrolled: false,
            visible: false,
            selectedDay: this.$moment(),
            commentVisible: false,
            loading: false,
            daily: null,
            taskWorkTypes: [],
            taskWorkTypesLoading: false,
            submitLoading: false,
            eventsLoading: false,
            descLoading: false,
            form: {
                description: "",
                plane_items: []
            },
            events: []
        }
    },
    watch: {
        visible(val) {
            if (val) {
                this.createMask()
            } else {
                if(this.form.plane_items?.length) {
                    this.form.plane_items.forEach((item, index) => {
                        if(item.commentVisible)
                            this.$set(this.form.plane_items[index], 'commentVisible', false)
                    })
                }
                this.commentVisible = false
                this.removeMask()
            }
        }
    },
    created() {
        eventBus.$on('add_task_in_my_work_plan', (task) => {
            this.getDaily(false, false)
                .then(async () => {
                    if (this.daily && this.daily.status.code === 'in_work') {
                        this.addItem(task)
                    } else if(this.daily && this.daily.status.code === 'completed') {
                        this.$message.error(`План рабочего дня на ${this.$moment(this.selectedDay).format('DD.MM.YYYY')} завершен`)
                    } else if(!this.daily) {
                        this.createDailyAndAddItem(task)
                    }
                })
                .catch(e => {
                    console.log(e)
                    this.$message.error('Ошибка получения плана рабочего дня')
                })
                .finally(() => {
                    this.visibleChange(false)
                })
        })
    },
    beforeDestroy() {
        eventBus.$off('add_task_in_my_work_plan')
    },
    computed: {
        checkDailyComment() {
            if(this.daily) {
                if(this.isCompleted)
                    return this.form.description ? true : false
                return true
            }
            return false
        },
        isTodaySelected() {
            return this.selectedDay.isSame(this.$moment(), 'day')
        },
        totalDurationFact() {
            return Math.round(this.form.plane_items.reduce((sum, item) => {
                const value = parseFloat(item.duration_fact) || 0;
                return sum + value;
            }, 0) * 10) / 10;
        },
        durationFactDisabled() {
            if(this.emptyDay)
                return true
            if(this.daily)
                return this.isCompleted
            return true
        },
        editedDaily() {
            if(this.daily) {
                const filter = this.form.plane_items.filter(f => f.edited || f.add)
                return filter.length ? true : false
            }
            return false
        },
        isCompleted() {
            if(this.daily?.status?.code === 'completed')
                return true
            return false
        },
        isFieldDisabled() {
            if(this.emptyDay)
                return true
            return this.isCompleted
        }
    },
    methods: {
        async addItem(task) {
            this.submitLoading = true
            const payload = {
                plane: this.daily.id,
                task: task.id
            }
            try {
                await this.$http.post(`/personal_planes/item/`, payload)
                this.$message.success(`Задача добавлена в план рабочего дня ${this.$moment(this.selectedDay).format('DD.MM.YYYY')}`)
            } catch(e) {
                console.log(e)
                this.$message.error('Ошибка добавления')
            } finally {
                this.submitLoading = false
            }
        },
        createDailyAndAddItem(task) {
            try {
                this.submitLoading = true
                const queryData = {
                    plane_date: this.selectedDay.format(dateFormat),
                    plane_items: [
                        {
                            task: task.id
                        }
                    ]
                }
                this.$http.post('/personal_planes/', queryData)
                this.$message.success(`Задача добавлена в план рабочего дня ${this.$moment(this.selectedDay).format('DD.MM.YYYY')}`)
            } catch(error) {
                this.messageConfig()
                if(Array.isArray(error)) {
                    this.$message.error({
                        content: error.join(','),
                        onClose: () => {
                            this.messageDefaultSetting()
                        }
                    })
                } else {
                    this.$message.error({
                        content: 'Ошибка',
                        onClose: () => {
                            this.messageDefaultSetting()
                        }
                    })
                }
                console.log(error)
            } finally {
                this.submitLoading = false
            }
        },
        disabledFutureDates(current) {
            return current && current.isAfter(this.$moment().endOf("day"))
        },
        checkTaskComment(item) {
            if(this.daily) {
                if(this.isCompleted) {
                    return item.description ? true : false
                }
                return true
            }
            return true
        },
        handleDeilyCommentChange(event) {
            const value = event.target.value.slice(0, this.commentMaxCount)
            this.$set(this.form, 'description', value)
        },
        handleCommentChange(event, index, item) {
            const value = event.target.value.slice(0, this.commentMaxCount)
            this.$set(this.form.plane_items[index], 'description', value)
            this.taskChange(index, item)
        },
        taskChange(index, item) {
            if(this.daily && !this.checkEdited())
                this.$refs.ruleForm.resetFields()
            if(!item.add)
                this.$set(this.form.plane_items[index], 'edited', true)
            /*if(this.daily) {
                if(!item.add) {
                    clearTimeout(editTimer)
                    this.$set(this.form.plane_items[index], 'edited', true)
                    editTimer = setTimeout(async () => {
                        try {
                            const queryData = {...item}
                            if(!queryData.duration_fact)
                                queryData.duration_fact = 0
                            if(!queryData.duration_plane)
                                queryData.duration_plane = 0
                            this.$set(this.form.plane_items[index], 'loading', true)
                            await this.$http.put(`/personal_planes/item/${queryData.id}/`, queryData)
                        } catch(e) {
                            console.log(e)
                            this.$message.error('Ошибка')
                        } finally {
                            this.$set(this.form.plane_items[index], 'loading', false)
                            this.$set(this.form.plane_items[index], 'edited', false)
                        }
                    }, 1000)
                } else {
                    if(item.task && item.duration_plane && item.description && item.work_type) {
                        clearTimeout(addTimer)
                        addTimer = setTimeout(async () => {
                            try {
                                const queryData = {...item}
                                if(!queryData.duration_fact)
                                    queryData.duration_fact = 0
                                if(!queryData.duration_plane)
                                    queryData.duration_plane = 0
                                queryData.plane = this.daily.id
                                this.$set(this.form.plane_items[index], 'loading', true)
                                const { data } = await this.$http.post('/personal_planes/item/', queryData)
                                if(data) {
                                    this.$set(this.form.plane_items[index], 'id', data.id)
                                    this.$delete(this.form.plane_items[index], 'add')
                                }
                            } catch(e) {
                                console.log(e)
                                this.$message.error('Ошибка')
                            } finally {
                                this.$set(this.form.plane_items[index], 'loading', false)
                            }
                        }, 1000)
                    }
                }
            }*/
        },
        openEvent(id) {
            this.visible = false
            let query = Object.assign({}, this.$route.query)
            if(query.event && Number(query.event) !== id || !query.event) {
                query.event = id
                this.$router.push({query})
            }
        },
        async getEvents() {
            try {
                this.eventsLoading = true
                const startDate = this.selectedDay.set('hour', 0).set('minute', 1).set('second', 1).set('millisecond', 0).toISOString(true),
                    endDate = this.selectedDay.set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 59).toISOString(true),
                    params = {
                        start: startDate,
                        end: endDate
                    }
                const { data } = await this.$http.get('/calendars/events/top/', { params })
                if(data) {
                    this.events = data
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.eventsLoading = false
            }
        },
        dailyHandle() {
            this.$refs.ruleForm.validate((valid, errors) => {
                if (valid) {
                    if(this.daily && !this.editedDaily)
                        this.completedDaily()
                    else
                        this.dailySave()
                } else {
                    /*if(Object.keys(errors)?.length) {
                        this.$nextTick(() => {
                            const firstItem = Object.values(errors)[0]
                            if(firstItem.length) {
                                const vElem = firstItem[0];
                                if (this.$refs[vElem.field]) {
                                    const taskElement = this.$refs[vElem.field];
                                    const container = this.$refs.taskList;
                                    const containerRect = useElementBounding(container)
                                    const elementRect = useElementBounding(taskElement)
                                    console.log(containerRect, 'containerRect')

                                    const elementRect = taskElement.getBoundingClientRect();
                                    const containerHeight = container.clientHeight;
                                    const elementHeight = elementRect.height;

                                    const elementTopInContainer = elementRect.top - containerRect.top + container.scrollTop;

                                    let desiredScrollTop;

                                    if (elementHeight > containerHeight) {
                                        desiredScrollTop = elementTopInContainer;
                                    } else {
                                        desiredScrollTop = elementTopInContainer - (containerHeight - elementHeight) / 2;
                                    }

                                    const maxScrollTop = container.scrollHeight - containerHeight;
                                    desiredScrollTop = Math.max(0, Math.min(desiredScrollTop, maxScrollTop));

                                    container.scrollTo({
                                        top: desiredScrollTop,
                                        behavior: "smooth"
                                    });
                                }
                            }
                        })
                    }*/
                    return false
                }
            })
        },
        async completedDaily() {
            try {
                this.submitLoading = true
                const { data } = await this.$http.post(`/personal_planes/${this.daily.id}/complete/`)
                if(data)
                    this.daily = data
            } catch(e) {
                console.log(e)
                this.$message.error('Ошибка')
            } finally {
                this.submitLoading = false
            }
        },
        async dailySave() {
            try {
                this.submitLoading = true
                const queryData = {...this.form}
                queryData.plane_date = this.selectedDay.format('YYYY-MM-DD')
                if(queryData.plane_items?.length) {
                    queryData.plane_items = queryData.plane_items.map(item => {
                        return {
                            ...item,
                            duration_fact: item.duration_fact || 0,
                            duration_plane: item.duration_plane || 0
                        }
                    })
                }
                if(this.editedDaily) {
                    const { data } = await this.$http.put(`/personal_planes/${this.daily.id}/`, queryData)
                    if(data) {
                        this.daily = data
                        this.form = dailyGenerate(data)
                    }
                } else {
                    const { data } = await this.$http.post('/personal_planes/', queryData)
                    if(data) {
                        this.daily = data
                        this.form = dailyGenerate(data)
                    }
                }
            } catch(error) {
                this.messageConfig()
                if(Array.isArray(error)) {
                    this.$message.error({
                        content: error.join(','),
                        onClose: () => {
                            this.messageDefaultSetting()
                        }
                    })
                } else {
                    this.$message.error({
                        content: 'Ошибка',
                        onClose: () => {
                            this.messageDefaultSetting()
                        }
                    })
                }
                console.log(error)
            } finally {
                this.submitLoading = false
            }
        },
        async getTaskWorkTypes() {
            try {
                this.taskWorkTypesLoading = true
                const { data } = await this.$http.get('/app_info/select_list/', {
                    params: {
                        model: 'tasks.TaskWorkTypeModel',
                        filters: JSON.stringify({work_type_task_type__task_type_id: 'task'})
                    }
                })
                if(data?.selectList) {
                    this.taskWorkTypes = data.selectList
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.taskWorkTypesLoading = false
            }
        },
        changeDate(e) {
            if(this.selectedDay.format(dateFormat) !== e.format(dateFormat)) {
                this.selectedDay = e
                this.getOtherDaily()
                this.$nextTick(() => {
                    this.$refs.taskList.scrollTop = 0
                    this.$refs.eventsList.scrollTop = 0
                })
            }
        },
        taskListScroll() {
            const taskList = this.$refs.taskList
            if (taskList.scrollTop > 0) {
                if (!this.isTaskScrolled) {
                    this.isTaskScrolled = true
                }
            } else {
                if (this.isTaskScrolled) {
                    this.isTaskScrolled = false
                }
            }
        },
        eventsListScroll() {
            const eventsList = this.$refs.eventsList
            if (eventsList.scrollTop > 0) {
                if (!this.isEventsScrolled) {
                    this.isEventsScrolled = true
                }
            } else {
                if (this.isEventsScrolled) {
                    this.isEventsScrolled = false
                }
            }
        },
        async addDailyComment() {
            this.commentVisible = false
            if(this.daily && !this.isCompleted) {
                try {
                    this.submitLoading = true
                    await this.$http.patch(`/personal_planes/${this.daily.id}/`, {
                        description: this.form.description
                    })
                } catch(e) {
                    console.log(e)
                    this.$message.error('Ошибка')
                } finally {
                    this.submitLoading = false
                }
            }
        },
        closeComment(index, item) {
            //this.$set(this.form.plane_items[index], 'description', item.oldDescription)
            this.$set(this.form.plane_items[index], 'commentVisible', false)
        },
        addComment(index, item) {
            this.$set(this.form.plane_items[index], 'commentVisible', false)
            /*if(item.description !== item.oldDescription) {
                try {
                    this.submitLoading = true
                    const { data } = await this.$http.patch(`/personal_planes/item/${item.id}/`, {
                        description: item.description
                    })
                    if(data) {

                    }
                    console.log(data, 'data')
                } catch(e) {
                    console.log(e)
                } finally {
                    this.submitLoading = false
                }
            }*/
        },
        taskScrollBottom() {
            this.$nextTick(() => {
                const taskList = this.$refs.taskList
                if (taskList)
                    taskList.scrollTop = taskList.scrollHeight - taskList.clientHeight
            })
        },
        async deleteItem(index, item) {
            if(item.add)
                this.$delete(this.form.plane_items, index)
            else {
                try {
                    this.$set(this.form.plane_items[index], 'dLoading', true)
                    const { data } = await this.$http.delete(`/personal_planes/item/${item.id}/`)
                    this.$delete(this.form.plane_items, index)
                } catch(e) {
                    console.log(e)
                    this.$message.error('Ошибка удаления')
                } finally {
                    if(this.form.plane_items[index])
                        this.$set(this.form.plane_items[index], 'dLoading', false)
                }
            }
        },
        checkEdited() {
            const filter = this.form.plane_items.filter(f => f.edited || f.add)
            return filter.length ? true : false
        },
        addTask() {
            if(this.daily && !this.checkEdited())
                this.$refs.ruleForm.resetFields()
            this.form.plane_items.push({
                key: Date.now(),
                work_type: null,
                dLoading: false,
                loading: false,
                task: null,
                add: true,
                duration_plane: null,
                duration_fact: null,
                commentVisible: false,
                description: ""
            })
            setTimeout(() => {
                this.taskScrollBottom()
            }, 100)
        },
        getPopupContainer() {
            return this.$refs.workPlanPopWrap
        },
        clearDaily() {
            this.daily = null
            this.selectedDay = this.$moment()
        },
        clearForm(vis = false) {
            this.isEventsScrolled = false
            this.isTaskScrolled = false
            this.commentVisible = false
            this.form = {
                description: "",
                plane_items: vis ? [] : [
                    {
                        key: 1,
                        work_type: null,
                        task: null,
                        duration_plane: null,
                        duration_fact: null,
                        commentVisible: false,
                        description: ""
                    }
                ]
            }
        },
        visibleChange(vis) {
            this.visible = vis
            if(!vis) {
                //clearTimeout(addTimer)
                //clearTimeout(editTimer)
                this.emptyDay = false
                this.messageDefaultSetting()
                this.clearDaily()
                this.clearForm(true)
            } else {
                this.getDaily(false)
                this.getTaskWorkTypes()
            }
        },
        messageDefaultSetting() {
            this.$message.config({
                maxCount: 3,
                getContainer: () => document.body
            })
            this.$message.destroy()
        },
        messageConfig() {
            this.$message.config({
                maxCount: 1,
                getContainer: this.getPopupContainer
            })
        },
        async getOtherDaily() {
            try {
                this.loading = true

                const { data } = await this.$http.get('/personal_planes/my/calendar/', {
                    params: { plane_date: this.selectedDay.format(dateFormat) }
                })

                if (data) {
                    this.emptyDay = false
                    this.daily = data
                    this.form = dailyGenerate(data)
                } else {
                    this.emptyDay = !this.selectedDay.isSame(this.$moment(), 'day')
                    this.daily = null
                    this.clearForm()
                }

                this.getEvents()
            } catch (e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        },
        async getDaily(change = false, getEvents = true) {
            try {
                this.loading = true

                const { data } = await this.$http.get('/personal_planes/my/current/', {
                    params: { plane_date: this.selectedDay.format(dateFormat) }
                })

                if (data) {
                    const isInWork = data.status?.code === 'in_work'
                    if (change && isInWork) {
                        this.messageConfig();
                        this.$message.warning({
                            content: 'Завершите рабочий день',
                            onClose: this.messageDefaultSetting
                        })
                    }
                    if (isInWork)
                        this.selectedDay = this.$moment(data.plane_date)

                    this.daily = data
                    this.form = dailyGenerate(data)
                } else {
                    if (this.daily) this.clearDaily()
                    this.clearForm()
                }
                if (getEvents) this.getEvents()
            } catch (e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        },
        createMask() {
            if (!document.querySelector('.popover_mask')) {
                const mask = document.createElement('div')
                mask.className = 'popover_mask'
                mask.addEventListener('click', this.closePopover)
                document.body.appendChild(mask)
            }
        },
        removeMask() {
            const mask = document.querySelector('.popover_mask')
            if (mask) {
                mask.removeEventListener('click', this.closePopover)
                document.body.removeChild(mask)
            }
        },
        closePopover() {
            this.visible = false
        }
    },
    beforeDestroy() {
        this.removeMask()
    }
}
</script>

<style lang="scss" scoped>
.comment_desc_text{
    word-break: break-word;
    max-width: 300px;
    max-height: 150px;
    overflow-y: auto;
}
.comment_length{
    font-size: 12px;
    opacity: 0.5;
}
.comment_desc{
    &:not(.validated){
        &::v-deep{
            .has-error{
                .ant-btn{
                    border-color: #f5222d;
                    color: #f5222d;
                }
            }
        }
    }
}
.empty_events{
    opacity: 0.6;
}
.comment_wrapper{
    min-width: 300px;
}
.item_delete{
    &::v-deep{
        .ant-btn{
            border: 0px;
            box-shadow: initial;
        }
    }
}
.form_item{
    &:not(:last-child){
        margin-bottom: 10px;
    }
    &__row{
        display: flex;
        align-items: center;
        position: relative;
        margin-left: -5px;
        margin-right: -5px;
        .form_item_col{
            padding-left: 5px;
            padding-right: 5px;
            &__1{
                flex: 0 0 auto;
                width: 27%;
            }
            &__2{
                flex: 0 0 auto;
                width: 35%;
            }
            &__3{
                flex: 0 0 auto;
                width: 15%;
            }
            &__4{
                flex: 0 0 auto;
                width: 15%;
            }
            &__5{
                flex: 0 0 auto;
                width: 8%;
            }
        }
    }
    /*&.long{
        grid-template-columns: 10px 1fr 1fr 100px 100px 60px;
    }*/
    &:not(:last-child){
        margin-bottom: 10px;
    }
    &::v-deep{
        .select_item{
            &:not(.selected){
                .ant-select-selection__placeholder{
                    display: block!important;
                }
            }
        }
        .ant-form-explain{
            display: none;
        }
    }
}
.wp_wrapper{
    min-width: 700px;
    max-width: 800px;
    color: #000;
    @media (min-width: 900px) {
        min-width: 800px;
    }
    &__header{
        padding: 15px;
        h4{
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
        }
    }
    &__body{
        padding: 0 15px;
        max-height: 120px;
        overflow-y: auto;
        @media (min-height: 450px) {
            max-height: 220px;
        }
        @media (min-height: 550px) {
            max-height: 320px;
        }
        @media (min-height: 650px) {
            max-height: 420px;
        }
        .body_block{
            background: #F8F8F8;
            border-radius: 4px;
            &:not(:last-child){
                margin-bottom: 10px;
            }
            &.body_block__events{
                .body_block__body{
                    max-height: 90px;
                }
            }
            &.body_block__tasks{
                .body_block__body{
                    max-height: 160px;
                }
            }
            &__head{
                padding: 15px 15px 10px 15px;
                transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
                .block_title{
                    margin: 0px;
                }
            }
            &__body{
                padding: 0 15px;
                overflow-y: auto;
                &:last-child{
                    padding-bottom: 15px;
                }
            }
            &__footer{
                padding: 5px 15px 15px 15px;
            }
            .event_item{
                cursor: pointer;
                user-select: none;
                span{
                    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                }
                &:hover{
                    span{
                        color: var(--blue);
                    }
                }
                &:not(:last-child){
                    margin-bottom: 6px;
                }
            }
        }
    }
    &__footer{
        padding: 15px;
        .work_hours{
            span{
                opacity: 0.6;
            }
        }
    }
    &::v-deep{
        .ant-message{
            position: absolute;
        }
    }
}
.work_plan_btn {
    &.ant-btn {
        &.ant-btn-primary {
            color: #000;
        }
    }
}
</style>

<style lang="scss">
.work_plan_popover{
    & > .ant-popover-content > .ant-popover-arrow{
        display: none;
    }
    & > .ant-popover-content > .ant-popover-inner > div > .ant-popover-inner-content{
        padding: 0px;
    }
}
.plan_comment_popover{
    .ant-popover-arrow{
        display: none;
    }
    .ant-popover-inner-content{
        padding: 15px;
    }
}
.popover_mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    z-index: 999;
}
</style>